import type { Tracker } from '@archipro-website/tracker';
import type { SocialMedia } from '~/modules/professional/type';

export const trackSocialMediaClick = (
    label: SocialMedia,
    itemID: number,
    title: string,
    tracker: Tracker
): void => {
    tracker.log('SocialMediaClick', {
        url: new URL(window.location.href),
        label: `${label}:${title}`,
        data: {
            LibraryItemID: itemID,
            Source: 'details, socialMedia',
            SocialMedia: label,
            ExtraData: label,
        },
    });
};
